import mixpanel from 'mixpanel-browser';

import {
  AnalyticsEvent,
  AnalyticsProviderType,
  DeviceInfo,
  User,
} from '../../types';

import { config } from '@/config';

export class MixpanelProvider implements AnalyticsProviderType {
  user?: User;
  deviceInfo?: DeviceInfo;

  constructor() {
    mixpanel.init(config.MIXPANEL_TOKEN, { track_pageview: true });
  }

  identify(userId: string) {
    mixpanel.identify(userId);
  }

  async sendEvent(event: AnalyticsEvent): Promise<void> {
    const eventData = {
      ...this.user,
      ...this.deviceInfo,
      ...event.data,
      timestamp: Date.now(),
    };
    mixpanel.track(event.name, eventData);
  }

  syncUser(user: User) {
    this.user = {
      ...this.user,
      ...user,
    };
  }

  async reset() {
    mixpanel.reset();
    this.user = undefined;
    this.deviceInfo = undefined;
  }
}

export const mixpanelSDK = new MixpanelProvider();
