import { SVGProps } from 'react';

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0003 18.3346C5.39795 18.3346 1.66699 14.6036 1.66699 10.0013C1.66699 5.39893 5.39795 1.66797 10.0003 1.66797C14.6027 1.66797 18.3337 5.39893 18.3337 10.0013C18.3337 14.6036 14.6027 18.3346 10.0003 18.3346ZM10.0003 16.668C13.6822 16.668 16.667 13.6832 16.667 10.0013C16.667 6.3194 13.6822 3.33464 10.0003 3.33464C6.31843 3.33464 3.33366 6.3194 3.33366 10.0013C3.33366 13.6832 6.31843 16.668 10.0003 16.668ZM9.16699 12.5013H10.8337V14.168H9.16699V12.5013ZM9.16699 5.83464H10.8337V10.8346H9.16699V5.83464Z"
        fill="#246DF0"
      />
    </svg>
  );
};
