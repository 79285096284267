import { VerificationDocType } from '@/lib/verification-service';

import { ScreenNameType, User } from '../types';

export enum screenNameMap {
  '/signup' = 'SIGNUP',
  '/signup/passcode/confirm' = 'CONFIRM_PASSCODE',
  '/signup/passcode/confirmed' = 'CONFIRMED_PASSCODE',
  '/signup/passcode/create' = 'CREATE_PASSCODE',
  '/signup/phone-verification' = 'PHONE_VERIFICATION',
  '/signup/quota-reached' = 'QUOTA_REACHED',
  '/signup/waitlist' = 'WAITLIST',
  '/signup/waitlist/done' = 'WAITLIST_DONE',
  '/signin' = 'SIGNIN',
  '/signin/confirm-passcode' = 'CONFIRM_PASSCODE',
  '/signout' = 'SIGNOUT',
  '/dashboard' = 'HOME',
  '/dashboard/account-details' = 'ACCOUNT_DETAILS',
  '/join' = 'JOIN_BY_INVITE_CODE',
  '/join/:inviteCode' = 'JOIN_BY_INVITE_CODE',
  '/invite' = 'INVITE',
  '/recipients' = 'RECIPIENTS',
  '/recipients/new' = 'RECIPIENTS_ADD',
  '/recipients/details/:id' = 'RECIPIENTS_DETAILS',
  '/recipients/empty' = 'RECIPIENTS_EMPTY',
  '/recipients/edit' = 'RECIPIENTS_EDIT',
  '/recipients/transactions/:id' = 'RECIPIENTS_TRANSACTIONS',
  '/transactions' = 'TRANSACTIONS',
  '/transactions/new' = 'TRANSACTIONS_NEW',
  '/transactions/new/payment-method' = 'TRANSACTIONS_PAYMENT_METHOD',
  '/transactions/new/processing' = 'TRANSACTIONS_PROCESSING',
  '/transactions/new/receipient' = 'TRANSACTIONS_RECIPIENT',
  '/transactions/new/review' = 'TRANSACTIONS_REVIEW',
  '/transactions/status' = 'TRANSACTIONS_STATUS',
  '/transactions/details/:id' = 'TRANSACTIONS_DETAILS',
  '/transactions/:id' = 'TRANSACTIONS_LIST',
  '/transactions/empty' = 'TRANSACTIONS_EMPTY',
  '/address-information' = 'ADDRESS_INFORMATION',
  '/v2/address-information' = 'ADDRESS_INFORMATION_V2',
  '/v2/address-information/review' = 'ADDRESS_INFORMATION_V2_REVIEW',
  '/kyc/id-verification' = 'KYC_ID_VERIFICATION',
  '/kyc/upgrade/:step' = 'KYC_UPGRADE',
  '/kyc/liveliness' = 'KYC_LIVELINESS',
  '/kyc/success' = 'KYC_SUCCESS',
  '/personal-information' = 'PERSONAL_INFORMATION',
  '/accounts/:id/add' = 'ADD_ACCOUNT',
  '/download-statement' = 'DOWNLOAD_STATEMENT',
  '/secondary-contact-verification/:contactType' = 'SECONDARY_CONTACT_VERIFICATION',
  '/settings/:tab' = 'SETTINGS',
  '/support' = 'SUPPORT',
  '/recover-passcode' = 'RECOVER_PASSCODE',
  '/recover-passcode/:action' = 'RECOVER_PASSCODE_ACTION',
  '/privacy-policy' = 'PRIVACY_POLICY',
  '/terms-of-service' = 'TERMS_OF_SERVICE',
  '/2fa/:step' = '2FA',
  '/v2/personal-information/verify' = 'PERSONAL_INFORMATION_VERIFY',
  '/v2/personal-information/email' = 'PERSONAL_INFORMATION_EMAIL',
  '/v2/personal-information/mobile-number' = 'PERSONAL_INFORMATION_MOBILE_NUMBER',
  '/v2/kyc/upgrade' = 'KYC_UPGRADE_V2',
  '/v2/kyc/upgrade/level-2' = 'KYC_UPGRADE_V2_LEVEL_2',
  '/v2/primary-contact-verification' = 'PRIMARY_CONTACT_VERIFICATION_V2',
  '/v2/stay-tuned' = 'STAY_TUNED_V2',
  '/v2/onboarding-complete' = 'ONBOARDING_COMPLETE_V2',
  '/contact-verification/:contactType' = 'CONTACT_VERIFICATION',
}

const matchPathname = (pattern: string, pathname: string): boolean => {
  const patternSegments = pattern?.split('/').filter(Boolean);
  const pathSegments = pathname?.split('/').filter(Boolean);
  if (patternSegments.length !== pathSegments?.length) return false;

  return patternSegments.every((segment, index) => {
    if (segment.startsWith(':')) return true;
    return segment === pathSegments[index];
  });
};

export const getScreenNameByPathname = (pathname: ScreenNameType) => {
  for (const pattern in screenNameMap) {
    if (matchPathname(pattern, pathname)) {
      return screenNameMap[pattern as ScreenNameType];
    }
  }
  return undefined;
};

export const getIdVerificationName = (
  verificationDocValue: VerificationDocType | 'DRIVERS_LICENSE',
) => {
  switch (verificationDocValue) {
    case VerificationDocType.RESIDENCE_PERMIT:
      return 'brp';
    case VerificationDocType.DRIVERS_LICENSE:
    case 'DRIVERS_LICENSE':
      return 'license';
    case VerificationDocType.PASSPORT:
      return 'passport';
    default:
      return '';
  }
};

export const mapSyncUserKeys = (data: Record<string, any>): Partial<User> => {
  return {
    email: data.email,
    phone_number: data.phoneInfo?.number,
    country: data.address.countryCode,
    username: data.username,
    city: data.address.city,
  };
};
