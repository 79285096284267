'use client';

import { usePathname } from 'next/navigation';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { VerificationDocType } from '../verification-service';
import { AnalyticsService } from './analytics-service';
import { customerIOSDK, mixpanelSDK, singularSDK } from './providers';
import { ScreenNameType } from './types';
import {
  getIdVerificationName,
  getScreenNameByPathname,
} from './utils/analytics-service-helper';

export const AnalyticsContext = createContext<AnalyticsService | undefined>(
  undefined,
);

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const pathname = usePathname();
  const hasMounted = useRef(false);

  const analyticsService = useMemo(() => {
    return new AnalyticsService([singularSDK, customerIOSDK, mixpanelSDK]);
  }, []);

  const handleDocumentVerification = (
    status: string,
    idDocType: VerificationDocType | 'DRIVERS_LICENSE',
  ) => {
    switch (status) {
      case 'SUCCESS':
        return `${getIdVerificationName(idDocType)}_verification_success`;
      case 'FAILED':
        return `${getIdVerificationName(idDocType)}_verification_failed`;
      default:
        return '';
    }
  };

  useEffect(() => {
    function onDocumentVerification(config: Record<string, any>) {
      const {
        status,
        payload: { idDocSetType, idDocType },
      } = config;
      if (idDocSetType === 'IDENTITY') {
        const eventName = handleDocumentVerification(status, idDocType);
        analyticsService.track({
          name: eventName,
          data: {},
        });
      }
    }

    analyticsService.on('documentVerificationCallback', onDocumentVerification);

    return () => {
      analyticsService.off(
        'documentVerificationCallback',
        onDocumentVerification,
      );
    };
  }, []);

  useEffect(() => {
    analyticsService.track({
      name: 'screen_view',
      data: {
        screen_name: getScreenNameByPathname(pathname as ScreenNameType),
        url: pathname,
      },
    });
    if (!hasMounted.current) {
      hasMounted.current = true;
    } else {
      /**
       * This is very specific user case to Singular Provider, as it need pageVisit method to run only after second page visit, on mount it will run automatically
       * As Mixpanel is already doing automatically, we dont need to do anything
       * with Mixapnel
       */
      analyticsService.pageVisit();
    }
  }, [pathname]);

  return (
    <AnalyticsContext.Provider value={analyticsService}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Custom hook to use the AnalyticsService
export const useAnalytics = (): AnalyticsService => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};
