'use client';

import { notification } from '@kamona/components';
import React, { ChangeEvent, ReactNode, useState } from 'react';

import { Button } from '@/ui/components/button';
import { Column } from '@/ui/components/flex';
import { Text } from '@/ui/components/text';
import {
  DrivingLicenseIcon,
  PassportIcon,
  ResidentPermitIcon,
  SkipIcon,
} from '@/ui/svgs';

import { useAnalytics } from '@/lib/analytics-service';
import { getIdVerificationName } from '@/lib/analytics-service/utils';
import {
  VerificationDocType,
  verificationService,
} from '@/lib/verification-service';

import { IdSelectorOption } from './id-selector-options';
import { PassportCountrySelector } from './passport-country-selector';

interface VerificationOption {
  value: string;
  icon?: ReactNode;
  title: string;
  description: string;
  buttonLabel?: string;
}

export const options: VerificationOption[] = [
  {
    value: 'RESIDENCE_PERMIT',
    icon: <ResidentPermitIcon />,
    title: 'British resident’s permit',
    description: 'Valid British resident’s permit (BRP)',
    buttonLabel: 'BRP',
  },
  {
    value: 'DRIVERS_LICENSE',
    icon: <DrivingLicenseIcon />,
    title: 'UK Driving license',
    description: 'Valid, unexpired UK driver’s license',
    buttonLabel: 'UK Driving License',
  },
  {
    value: 'PASSPORT',
    icon: <PassportIcon />,
    title: 'International passport',
    description: 'Bio page of your valid international passport',
    buttonLabel: 'Passport',
  },
  {
    value: 'SKIP',
    icon: <SkipIcon />,
    title: 'Skip this step',
    description: 'Choose this to continue without ID check',
    buttonLabel: 'no ID',
  },
];

type IdSelectorProps = {
  resolve: (value: Record<string, any>) => void;
  canSkip: boolean;
};

export function IdSelector({ resolve, canSkip }: Readonly<IdSelectorProps>) {
  const analytics = useAnalytics();

  const [selectedOption, setSelectedOption] = useState<VerificationOption>();
  const [showPassportSelection, setShowPassportSelection] =
    useState<boolean>(false);

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = options.find((option) => option.value === e.target.value);
    setSelectedOption(value);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      notification.error({
        title: 'Please select an option!',
        position: 'top-center',
        variant: 'filled',
      });
      return;
    }

    if (selectedOption.value === 'SKIP') {
      analytics.track({
        name: `skip_id_verification_initiated`,
        data: {},
      });
      resolve({
        result: selectedOption.value,
      });
    } else if (selectedOption.value === 'PASSPORT') {
      analytics.track({
        name: `passport_verification_initiated`,
        data: {},
      });
      setShowPassportSelection(true);
    } else {
      analytics.track({
        name: `${getIdVerificationName(selectedOption.value as VerificationDocType)}_verification_initiated`,
        data: {},
      });
      verificationService.launchDocumentVerification({
        documentType: selectedOption.value as VerificationDocType,
      });
    }
  };

  const handlePassportCountrySelected = (value: string) => {
    analytics.track({
      name: 'passport_country_success',
      data: {
        passport_country: value,
      },
    });
    //country needs to be alpha 3 code e.g NGA for Nigeria, GBR for Great Britain
    verificationService.launchDocumentVerification({
      documentType: VerificationDocType.PASSPORT,
      country: value,
    });
  };

  if (showPassportSelection) {
    return (
      <PassportCountrySelector onContinue={handlePassportCountrySelected} />
    );
  }

  return (
    // TODO: Choose Passport Country
    <Column className="w-full gap-y-units-unit32">
      <Text
        align="center"
        weight="heading"
        variant="header-default"
        color="content-heading-default"
      >
        Choose an identity card
      </Text>

      <div className="space-y-4">
        {options.map((option) => {
          if (!canSkip && option.value === 'SKIP') return null;
          return (
            <IdSelectorOption
              key={option.value}
              value={option.value}
              icon={option.icon}
              title={option.title}
              description={option.description}
              selectedOption={selectedOption?.value}
              onOptionChange={handleOptionChange}
            />
          );
        })}
      </div>
      <div className="mt-units-unit32">
        <Button block type="submit" onClick={handleContinue}>
          Continue {selectedOption && `with ${selectedOption.buttonLabel}`}
        </Button>
      </div>
    </Column>
  );
}
