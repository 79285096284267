import { Row } from '@/ui/components/flex';
import type { ChangeEvent, ReactNode } from 'react';
import { Text } from '@/ui/components/text';

interface CardOptionProps {
  value: string;
  icon: ReactNode;
  title: string;
  description: string;
  selectedOption?: string;
  onOptionChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function IdSelectorOption({
  value,
  icon,
  title,
  description,
  selectedOption,
  onOptionChange,
}: CardOptionProps) {
  return (
    <label htmlFor={value} className="block cursor-pointer" data-testid="id-selector-option">
      <input
        type="radio"
        id={value}
        name="verification"
        value={value}
        className="hidden"
        data-testid={value}
        role="radio"
        checked={selectedOption === value}
        onChange={onOptionChange}
      />
      <Row
        align="center"
        className={`rounded-xl border border-ui-border-alternative bg-background-page-default px-3 ${
          selectedOption === value
            ? 'border-ui-border-dark'
            : 'border-ui-border-alternative'
        }`}
      >
        <div className="me-3 py-6">{icon}</div>
        <div className="py-5">
          <Text
            as="h2"
            variant="body-default"
            weight="semibold"
            color="content-heading-default"
          >
            {title}
          </Text>
          <Text
            as="p"
            variant="body-default"
            weight="normal"
            color="content-subtext-default"
          >
            {description}
          </Text>
        </div>
      </Row>
    </label>
  );
}
