'use client';

import { ChevronDown } from 'lucide-react';
import * as React from 'react';

import { useA11yDialog } from '@/shared/hooks';
import { CountryCode } from '@/shared/types';

import { countryCollection } from '@/lib/constants';
import { cn } from '@/lib/utils';

import {
  BottomSheet,
  BottomSheetContent,
  BottomSheetHeader,
  BottomSheetTitle,
} from '../bottom-sheet';
import {
  CountryAutocomplete,
  CountryAutocompleteProps,
} from '../country-autocomplete';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  useDialog,
} from '../dialog';
import FlagComponent from '../flag';
import { Text } from '../text';

export type CountryComboboxProps = Omit<CountryAutocompleteProps, 'ref'> &
  React.ComponentPropsWithoutRef<'button'> & {
    error?: boolean;
    placeholder?: boolean;
  };

export const CountryCombobox = React.forwardRef<
  HTMLButtonElement,
  CountryComboboxProps
>(
  (
    {
      value,
      options,
      className,
      onValueChange,
      placeholder,
      error = false,
      ...props
    },
    ref,
  ) => {
    const dialogId = React.useId();
    const { open, onOpenChange, onOpen } = useDialog();
    const { isDesktop, contentProps, controlProps } = useA11yDialog({
      open,
      id: dialogId,
    });

    const selectedCountry = countryCollection[value as CountryCode];

    const autocomplete = (
      <CountryAutocomplete
        {...{
          value,
          options,
          onValueChange: (...args) => {
            // Close the dialog when a country is selected
            onOpenChange(false);
            onValueChange(...args);
          },
        }}
      />
    );

    return (
      <div id='country-selector'>
        <button
          ref={ref}
          {...props}
          {...controlProps}
          type="button"
          role="combobox"
          aria-controls='country-selector'
          aria-expanded={open}
          onClick={onOpen}
          className={cn(
            'flex h-10 w-full items-center gap-2 rounded-md border border-ui-border-alternative px-3 hover:bg-ui-input-hovered disabled:cursor-not-allowed disabled:bg-ui-input-disabled disabled:opacity-50',
            {
              'border-feedback-danger-filled-default': error,
            },
            className,
          )}
        >
          {selectedCountry ? (
            <>
              <FlagComponent countryCode={value} />
              <Text
                weight="medium"
                className="flex-grow"
                variant="body-default"
                color="content-body-default"
              >
                {selectedCountry.name}
              </Text>
            </>
          ) : (
            <Text
              weight="medium"
              variant="body-default"
              color="content-label-default"
            >
              Select Country
            </Text>
          )}
          <ChevronDown size={20} className="text-content-label-default" />
        </button>
        {isDesktop ? (
          <Dialog {...{ open, onOpenChange }}>
            <DialogContent {...contentProps}>
              <DialogBody>
                <DialogHeader>
                  <DialogTitle>Select Country</DialogTitle>
                </DialogHeader>
                {autocomplete}
              </DialogBody>
            </DialogContent>
          </Dialog>
        ) : (
          <BottomSheet {...{ open, onOpenChange }}>
            <BottomSheetContent {...contentProps}>
              <BottomSheetHeader className="items-start">
                <BottomSheetTitle>Select Country</BottomSheetTitle>
              </BottomSheetHeader>
              {autocomplete}
            </BottomSheetContent>
          </BottomSheet>
        )}
      </div>
    );
  },
);
CountryCombobox.displayName = 'CountryCombobox';
