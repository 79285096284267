import { Text } from '../text';
import { useState } from 'react';
import { CountryCombobox } from './country-combobox';

export function CountryComboboxDemo() {
  const [value, onValueChange] = useState('');

  return (
    <>
      <Text variant="header-default">Country Combobox demo</Text>
      <CountryCombobox {...{ value, onValueChange }} />
    </>
  );
}
