type EventCallback<T> = (value: T) => void;

export class CustomEventEmitter<T> {
  private eventListeners: { [key: string]: EventCallback<T>[] } = {};

  on(eventType: string, callback: EventCallback<T>): void {
    if (!this.eventListeners[eventType]) {
      this.eventListeners[eventType] = [];
    }
    this.eventListeners[eventType].push(callback);
  }

  off(eventType: string, callback: EventCallback<T>): void {
    const listeners = this.eventListeners[eventType];
    if (listeners) {
      this.eventListeners[eventType] = listeners.filter(
        (cb) => cb !== callback,
      );
    }
  }

  emit(eventType: string, value: T): void {
    const listeners = this.eventListeners[eventType];
    if (listeners) {
      listeners.forEach((callback) => callback(value));
    }
  }
}
