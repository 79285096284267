import { useEffect, useState } from 'react';

import { verificationService } from './verification-service';

export function useVerificationConfig() {
  const [config, setConfig] = useState<Record<string, any>>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    function onConfig(config: Record<string, any>) {
      setConfig(config);
    }

    function onLoadingStateChanged(loading: boolean) {
      setLoading(loading);
    }

    verificationService.on('config', onConfig);
    verificationService.on('loading', onLoadingStateChanged);

    return () => {
      verificationService.off('config', onConfig);
      verificationService.off('config', onLoadingStateChanged);
    };
  }, []);

  return { config, loading };
}
