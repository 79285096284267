import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

import {
  AnalyticsEvent,
  AnalyticsProviderType,
  DeviceInfo,
  User,
} from '../../types';

import { config } from '@/config';

export class CustomerIOProvider implements AnalyticsProviderType {
  cio: AnalyticsBrowser;
  user?: User;
  deviceInfo?: DeviceInfo;

  constructor() {
    this.cio = AnalyticsBrowser.load({
      cdnURL: 'https://cdp-eu.customer.io',
      writeKey: config.CUSTOMER_IO_WRITE_KEY,
    });
  }

  async sendEvent(event: AnalyticsEvent): Promise<void> {
    const eventData = {
      ...this.user,
      ...this.deviceInfo,
      ...event.data,
    };
    this.cio.track(event.name, eventData);
  }

  identify(userId: string) {
    this.cio.identify(userId);
  }

  syncUser(user: User): void {
    this.user = {
      ...this.user,
      ...user,
    };
  }

  async reset(): Promise<void> {
    await this.cio.reset();
    this.user = undefined;
    this.deviceInfo = undefined;
  }
}

export const customerIOSDK = new CustomerIOProvider();
