import {
  MockServiceProvider,
  SumsubServiceProvider,
} from './service-providers';
import {
  DocumentVerificationConfig,
  LikenessCheckPayload,
  LivenessCheckConfig,
  VerificationServiceProvider,
} from './types';
import { CustomEventEmitter } from './util';

import { config } from '@/config';

export class VerificationService {
  private provider: VerificationServiceProvider;
  private eventManager = new CustomEventEmitter();
  user: Record<string, any> | undefined;

  constructor() {
    this.provider = this.getProvider();
    this.provider.setEmitter(this.eventManager);
    if (this.user) this.syncUser(this.user);
  }

  getProvider() {
    switch (config.VERIFICATION_PROVIDER) {
      case 'sumsub':
        return new SumsubServiceProvider();
      default:
        return new MockServiceProvider();
    }
  }

  on(type: string, callback: (value: any) => void) {
    this.eventManager.on(type, callback);
  }

  off(type: string, callback: (value: any) => void) {
    this.eventManager.on(type, callback);
  }

  launchLivelinessCheck(config?: LivenessCheckConfig) {
    return this.provider.performLiveliness(config);
  }

  launchDocumentVerification(config?: DocumentVerificationConfig) {
    return this.provider.performDocumentVerification(config);
  }

  launchLikenessCheck(payload: LikenessCheckPayload) {
    return this.provider.performLikenessCheck(payload);
  }

  syncUser(user: Record<string, any>) {
    this.user = user;
    this.provider.syncUser(user);
  }
}

export enum VerificationDocType {
  PASSPORT = 'PASSPORT',
  DRIVERS_LICENSE = 'DRIVERS',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
}

export const verificationService = new VerificationService();
