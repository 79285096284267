import SumsubWebSdk from '@sumsub/websdk-react';
import SumsubWebSdkProps from '@sumsub/websdk-react/types/src/SumsubWebSdkProps';

import { useVerificationConfig } from '../../use-verification-config';
import { IdSelector } from './id-selector/id-selector';

export function SumsubDelegate() {
  const { config, loading } = useVerificationConfig();

  if (loading)
    return (
      <div
        className="border-units-unit4 border-primary/base 
        border-b-transparent rounded-full 
        animate-spin mt-[100px] !w-[40px] !h-[40px]"
      />
    );

  if (config?.action === 'select-id')
    return <IdSelector resolve={config.resolve} canSkip={config.canSkip} />;

  if (!config?.accessToken) return null;

  return (
    <SumsubWebSdk
      key={JSON.stringify(config)}
      {...(config as SumsubWebSdkProps)}
    />
  );
}
