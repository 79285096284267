'use client';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../command';
import { Text } from '../text';
import { CountryCode } from '@/shared/types';
import { countryCollection } from '@/lib/constants';
import FlagComponent from '../flag';

export interface CountryAutocompleteProps
  extends Omit<React.ComponentProps<typeof Command>, 'value'> {
  /** The selected country code */
  value: string;
  /** List of countries that can be selected, defaults to {@link countryCollection} */
  options?: string[];
  /** Handler for when a country gets selected */
  onValueChange: (value: string) => void;
}

export function CountryAutocomplete({
  value,
  options,
  onValueChange,
  ...props
}: CountryAutocompleteProps) {
  const countryCodes =
    options || (Object.keys(countryCollection) as CountryCode[]);

  return (
    <Command {...props}>
      <CommandInput
        placeholder="Search"
        data-testid="country-autocomplete-input"
        className="text-body-default font-medium leading-body-default tracking-body-default placeholder:font-medium placeholder:text-content-label-alternative"
        wrapperProps={{
          className: 'border rounded-xl border-ui-border-alternative',
        }}
        iconProps={{
          strokeWidth: 3,
          className: 'w-[18px] h-[18px]',
        }}
      />
      <CommandList className="mt-4">
        <CommandEmpty>No country found</CommandEmpty>
        <CommandGroup className="p-0">
          {countryCodes.map((countryCode) => {
            const country = countryCollection[countryCode as CountryCode];

            return (
              <CommandItem
                key={countryCode}
                value={country.name}
                className="rounded-xl px-2 py-3"
                aria-selected={value === countryCode}
                onSelect={(currentValue) => {
                  // Return the country code instead of the name
                  onValueChange(currentValue === value ? '' : countryCode);
                }}
              >
                <FlagComponent countryCode={countryCode} />
                <Text variant="body-default" className="ml-3">
                  {country.name}
                </Text>
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
