import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { HTMLAttributes, forwardRef } from 'react';

import { createComponent } from '@/ui/system';

import { cn } from '@/lib/utils';

import { BaseButton, BaseButtonProps } from '../base-button';
import { Button, ButtonProps } from '../button';
import { Text, TextProps } from '../text';

const alertVariants = cva(
  'group relative rounded-xl border border-slate-200 flex items-start gap-2.5 items-center px-[12px] py-[20px]',
  {
    variants: {
      variant: {
        default: 'bg-white text-slate-950 ',
        destructive: 'border-red-500/50 text-red-500  [&>svg]:text-red-500',
        outline:
          'border-ui-border-alternative text-content-heading bg-background-plain-cards',
        filled: 'bg-background-inset-default border-0',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const Alert = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
));
Alert.displayName = 'Alert';

const AlertContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex flex-1 flex-col gap-4', className)}
      {...props}
    />
  ),
);
AlertContent.displayName = 'AlertContent';

const AlertIcon = forwardRef<
  SVGElement,
  HTMLAttributes<SVGElement> & {
    renderIcon: (props: { size: number; className: string }) => JSX.Element;
  }
>(({ renderIcon, className }, ref) =>
  renderIcon({ size: 24, className: cn('flex-shrink-0 w-6 h-6', className) }),
);
AlertIcon.displayName = 'AlertIcon';

const AlertTitle = forwardRef<HTMLParagraphElement, TextProps>(
  ({ className, ...props }, ref) => (
    <Text
      ref={ref}
      variant="body-default"
      className={cn('font-semibold', className)}
      {...props}
    />
  ),
);
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = forwardRef<HTMLParagraphElement, TextProps>(
  ({ className, ...props }, ref) => (
    <Text
      ref={ref}
      variant="body-default"
      className={cn('font-normal', className)}
      {...props}
    />
  ),
);
AlertDescription.displayName = 'AlertDescription';

const AlertAction = React.forwardRef<
  React.ElementRef<typeof Button>,
  Omit<ButtonProps, 'variant'>
>((props, ref) => <Button ref={ref} variant="outline" {...props} />);
AlertAction.displayName = 'AlertAction';

const AlertIconAction = createComponent<BaseButtonProps>((props, ref) => (
  <BaseButton
    ref={ref}
    size="tiny"
    layout="icon"
    variant="outline"
    {...props}
  />
));
AlertIconAction.displayName = 'AlertIconAction';

export {
  Alert,
  AlertAction,
  AlertContent,
  AlertDescription,
  AlertIcon,
  AlertIconAction,
  AlertTitle,
};
