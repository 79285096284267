import { Text } from '../text';
import { useState } from 'react';
import { CountryAutocomplete } from './country-autocomplete';

export function CountryAutocompleteDemo() {
  const [value, onValueChange] = useState('');

  return (
    <>
      <Text variant="header-default">Country Autocomplete demo</Text>
      <CountryAutocomplete {...{ value, onValueChange }} />
    </>
  );
}
