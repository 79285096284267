import { config } from '@/config';
import { MockDelegate, SumsubDelegate } from './service-providers';

export function VerificationProvider() {
  if(config.VERIFICATION_PROVIDER === 'sumsub') 
  {
    return <SumsubDelegate />;
  }

  return <MockDelegate />;
}
