interface Country {
  callingCode: string;
  name: string;
  alpha3Code: string;
  alpha2Code: string
}

export const supportedCountries: Country[] = [
  {
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    name: 'Nigeria',
    callingCode: '234',
  },
];
